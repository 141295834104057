import { useContext, useEffect, useState } from 'react';
import {
  ProgressLine,
  BackButton,
  CloseButton,
  SurveyAnswer,
  SurveyResult,
  PostStore,
  postSurvey,
  CaseTokenParams,
} from '@adac/core-view';
import { observer } from 'mobx-react';
import { CaseDTO } from '@adac/core-model';
import { FlexView } from '../../ui';
import YesNoOverlay from './YesNoOverlay';
import StoresContext from '../../../stores';
import SmileyFeedbackOverlay from './SmileyFeedbackOverlay';
import OpinionOverlay from './OpinionOverlay';
import ThankYou from '../ThankYou';

export interface QuestionProps {
  question: {
    text1?: string;
    text2: string;
  };
}

const SurveyOverlays = observer(() => {
  const { case: caseStore } = useContext(StoresContext);

  const maxQuestion = 5;
  const questions = [
    { text1: 'Waren Sie zufrieden mit', text2: 'der Wartezeit?' },
    { text1: 'Waren Sie zufrieden mit', text2: 'der Türöffnung an sich?' },
    {
      text1: 'Waren Sie zufrieden mit',
      text2: 'dem Preis-Leistungs-Verhältnis?',
    },
    {
      text2:
        'Wie zufrieden sind Sie insgesamt mit dem Schlüsselnotdienst durch den ADAC?',
    },
    {
      text2:
        'Gibt es noch etwas, das Sie dem ADAC Schlüsselnotdienst mitteilen wollen?',
    },
  ];

  const [isFinished, setIsFinished] = useState(false);
  const [answers, setAnswers] = useState<SurveyAnswer[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const onAnswered = (answer: SurveyAnswer) => {
    setAnswers([...answers, answer]);
    const newIndex = currentQuestionIndex + 1;
    setCurrentQuestionIndex(newIndex);
    if (newIndex >= maxQuestion) {
      setIsFinished(true);
    }
  };

  useEffect(() => {
    (async () => {
      if (isFinished) {
        const postData: SurveyResult[] = questions.map((q, i) => ({
          question: `${q.text1 || ''} ${q.text2}`.trimLeft(),
          answer: answers[i],
        }));
        const surveyStore = new PostStore<
          Required<CaseTokenParams<string>>,
          CaseDTO,
          SurveyResult[]
        >(postSurvey);
        const item = await surveyStore.postItem(
          { caseToken: caseStore.token, accessToken: caseStore.accessToken },
          postData
        );
        await caseStore.handleStoreAction(surveyStore, item);
      }
    })();
    // shouldn't run otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const question = questions[currentQuestionIndex];
  return (
    <FlexView
      column
      center
      background='#fff'
      fullHeight
      style={{ minHeight: '30vh' }}
    >
      <ProgressLine count={5} progress={currentQuestionIndex} />
      <CloseButton Button={BackButton} />
      <FlexView>
        {!isFinished && (
          <>
            {currentQuestionIndex < 3 && (
              <YesNoOverlay onAnswered={onAnswered} question={question} />
            )}
            {currentQuestionIndex === 3 && (
              <SmileyFeedbackOverlay
                onAnswered={onAnswered}
                question={question}
              />
            )}
            {currentQuestionIndex === 4 && (
              <OpinionOverlay onAnswered={onAnswered} question={question} />
            )}
          </>
        )}
        {isFinished && <ThankYou />}
      </FlexView>
    </FlexView>
  );
});

export default SurveyOverlays;
