import { useState } from 'react';
import { SubTitle, TextField, Button, SubPage } from '@adac/core-view';
import styled from 'styled-components';
import { __ } from '@adac/core-model';
import { QuestionProps } from './SurveyOverlays';

interface Props extends QuestionProps {
  onAnswered: (opinion: string) => void;
}

const Title = styled(SubTitle)`
  margin-bottom: 24px;
`;

const SendButton = styled(Button)`
  margin-top: 16px;
  margin-bottom: 20px;
`;

const SkipText = styled(SubTitle)`
  margin: 20px auto;
`;

const OpinionOverlay = ({ question, onAnswered }: Props) => {
  const [opinion, setOpinion] = useState('');

  return (
    <SubPage>
      <Title center big>
        {question.text2}
      </Title>
      <TextField
        name='opinion'
        badgeTitle='Ihre Meinung'
        badgeEqualsPlaceholder
        defaultValue={opinion}
        rows={4}
        onChange={({ target: { value } }) => setOpinion(value)}
      />
      {opinion && (
        <SendButton
          margin
          cta
          title='Absenden'
          onClick={() => onAnswered(opinion)}
        />
      )}

      <SkipText center onClick={() => onAnswered('')}>
        {__('überspringen')}
      </SkipText>
    </SubPage>
  );
};

export default OpinionOverlay;
