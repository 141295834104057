import {
  LogoMobile,
  LogoOpenhouse,
  FlexView,
  LogoOpenhouseComponent,
  LogoMobileComponent,
} from '@adac/core-view';

import { observer } from 'mobx-react';
import WorkaroundStatusChangerCointainer from '../dev/WorkaroundStatusChangerCointainer';
import PhoneIcon from './NavBarContainer';
import { useIsInsuranceProduct } from '../../hooks/useIsInsuranceProduct';

const AdacLogo = observer(() => {
  const isInsurance = useIsInsuranceProduct();
  if (isInsurance) return <LogoMobileComponent />;
  return (
    <LogoMobile href='https://www.adac.de/produkte/schluesselnotdienst/' />
  );
});

const SndCustomerLogo = observer(() => {
  const isInsurance = useIsInsuranceProduct();
  if (isInsurance) return <LogoOpenhouseComponent />;
  return (
    <LogoOpenhouse href='https://www.adac.de/produkte/schluesselnotdienst/' />
  );
});

const Header = () => (
  <FlexView start='true' center>
    <AdacLogo />
    <SndCustomerLogo />
    <WorkaroundStatusChangerCointainer />
    <PhoneIcon />
  </FlexView>
);

export default Header;
