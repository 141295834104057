import styled from 'styled-components';
import {
  Text,
  SubTitle,
  Button,
  FlexView,
  View,
  ButtonStyles,
  Icon,
} from '@adac/core-view';
import { capitalize, __ } from '@adac/core-model';
import { QuestionProps } from './SurveyOverlays';

const Title = styled(Text)`
  margin-bottom: 8px;
`;

const Title2 = styled(SubTitle)`
  margin-bottom: 56px;
`;

const Buttons = styled(FlexView)`
  > :first-child {
    margin-right: 50px;
  }
  > :last-child {
    margin-left: 50px;
  }
  margin-bottom: 50px;

  ${ButtonStyles} {
    & > button,
    & > a {
      padding: 0;
    }
  }
  ${Text} {
    margin-top: 8px;
  }
`;

interface Props extends QuestionProps {
  onAnswered: (yesOrNo: boolean) => void;
}

const YesNoOverlay = ({ question, onAnswered }: Props) => (
  <View>
    <Title center>{question.text1}</Title>
    <Title2 center big>
      {question.text2}
    </Title2>
    <Buttons wrap center>
      <View>
        <Button onClick={() => onAnswered(true)} cta>
          <Icon size='64px' name='thumbs-up' />
        </Button>
        <Text center marginTop='8px'>
          {capitalize(__('yes'))}
        </Text>
      </View>
      <View>
        <Button onClick={() => onAnswered(false)} cta>
          <Icon size='64px' name='thumbs-down' />
        </Button>
        <Text center>{capitalize(__('no'))}</Text>
      </View>
    </Buttons>
  </View>
);

export default YesNoOverlay;
