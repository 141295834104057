import { useState, useContext } from 'react';
import { Text, View, BasicDevTool } from '@adac/core-view';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import StoreContext from '../../stores';

export default observer(() => {
  const stores = useContext(StoreContext);
  const { case: caseStore } = stores;
  const [open, setOpen] = useState(false);

  return (
    <BasicDevTool open={open} setOpen={setOpen} stores={stores}>
      <section>
        <Link to='/dev/list/icons'>See Icon list</Link>
      </section>

      <h1>Form input details</h1>
      <section>
        <Text>
          Location:
          {`${caseStore?.customerFullAddressString || '?'}`}
          =&gt;
          {caseStore?.customerCoordsString || '?'}
        </Text>
      </section>
      <section>
        <View
          height='60px'
          fullWidth
          style={{ overflow: 'scroll', maxWidth: '300px' }}
        >
          <small>
            {caseStore.token}
            {/* #{JSON.stringify(caseStore.errors)} */}
          </small>
        </View>
      </section>
    </BasicDevTool>
  );
});
