import React from 'react';
import styled from 'styled-components';

import {
  StaticMenuList,
  CopyRight,
  TabBarStyle,
  View,
  CookieContext,
} from '@adac/core-view';
import { links } from '../../content/index';

const FooterStyles = styled(View)`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > * {
    width: 100%;
  }
`;

const CookieBannerWhiteSpace = styled(View)`
  height: ${(p) => p.theme.spacing.cookieBannerHeight};
  width: 100%;
`;

export default () => {
  const { value: shouldHideCookieBanner } = React.useContext(CookieContext);

  return (
    <FooterStyles>
      <TabBarStyle padding>
        <CopyRight subTitle>© 2019 ADAC</CopyRight>
        <StaticMenuList items={links.footer} />
      </TabBarStyle>
      {!shouldHideCookieBanner && <CookieBannerWhiteSpace />}
    </FooterStyles>
  );
};
