import { useEffect } from 'react';
import { CaseStore, useParams } from '@adac/core-view';

export function useStatusListeners(caseStore: CaseStore) {
  const params = useParams();

  useEffect(() => {
    caseStore.initSocketListeners();
    return () => {
      caseStore.closeSocketListeners();
    };
  }, [caseStore, params.root_path]);
}
